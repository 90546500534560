import React, { Component } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <footer className="text-center footer" style={{ backgroundColor: "#000" }}>
        <div className="justify-content-around footer-distributed text-center">
          <a href="/legal-notice" className="text-color text-size">
            IMPRINT
          </a>
          <a href="/data-policy" className="text-color text-size">
            DATA POLICY
          </a>
          <a href="/contact" className="text-color">
            CONTACT
          </a>
        </div>
      </footer>
    );
  }
}
