import React from 'react';
import "./Header.css";
import logo from "../Images/logo.png";

/**
* @author
* @function Header
**/

export const Header = (props) => {
  return(
    <div>
        <div className='bannerSection'>
              <div className="logo" onClick={() => this.handleRoute}>
                  <a href="/">
                      <img src={logo} alt="" />
                  </a>
              </div>
              <div className="text-center mt-5 webTopText">
                  <h1 className="title-text">We enable digital and <br /> sustainable business</h1>

                  <p className="content-text">
                      

                      ILI.HOLDING incubates and invests in digital and sustainable driven companies.
                      We provide creativity, competences and capital to support entrepreneurs and help them
                      create trend-setting and successful companies with innovative and unique value propositions.
                  </p>
              </div>
        </div>

    </div>
   )

 }