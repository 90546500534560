import React, { Component } from "react";
import "./Home.css";
import incubationImg from "../Images/incubation.png";
import intelligenceImg from "../Images/intelligence.png";
import investmentImg from "../Images/Investment.png";
import { Header } from "../Header/Header";
export default class Home extends Component {
  render() {
    return (
      <div className="body p-0">
        <Header />
        
        <div className="pageContainer">
                 
          <div className="text-center mb-4 image-top">
            <h2>WHAT WE DO</h2>
          </div>
          <div className="offerSection grid">
            <div className="row justify-content-center align-items-center d-flex">
              
              <div className="col-md-3">
                <div className='offerInner-box justify-content-center align-items-center'>
                  <img className="incubation-img" src={incubationImg} alt="" />
                  <h2 className="mt-3 heading-shadow">
                    <b>INCUBATION</b>
                  </h2>
                  <p className="mt-4">
                    We leverage our sense for
                    <br /> opportunities to build innovative
                    <br /> and unique companies from
                    <br /> scratch.
                  </p>
                </div>
               
              </div>
              <div className="col-md-3">
                <div className='offerInner-box justify-content-center align-items-center'>
                <img className="incubation-img" src={investmentImg} alt="" />
                <h2 className="mt-3 heading-shadow">
                  <b>INVESTMENT</b>
                </h2>
                <p className="mt-4">
                  We partner with daring
                  <br /> entrepreneurs to build and run
                  <br /> trend-setting companies to
                  <br />
                  influence change.
                </p>
              </div>
              </div>
              <div className="col-md-3">
                <div className='offerInner-box justify-content-center align-items-center'>
                <img className="incubation-img" src={intelligenceImg} alt="" />
                <h2 className="mt-3 heading-shadow">
                  <b>INTELLIGENCE</b>
                </h2>
                <p className="mt-4">
                  We make digital companies
                  <br /> outrageously fast and powerful
                  <br /> with deep operational support.
                </p>
              </div>
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <p>
              ILI.HOLDING means being part of a unique future-focused team in
              <br />
              which outstanding people and great masterminds make the difference
              - especially in a digital world.
              <br />
              Together we compete to influence change and to disrupt markets all
              over the world.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
