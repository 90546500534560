import React, { Component } from "react";
import "./Contact.css";
import Footer from "../Footer/Footer";
import { withRouter } from "react-router-dom";
import whiteIcon from "../Images/black.png";

class Contact extends Component {
  handleRoute = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div style={{ height: "93vh" }}>
        <div className="header-class">
          <img
            src={whiteIcon}
            alt=""
            style={{
              float: "right",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
              width: "14rem",
              cursor:"pointer"
            }}
            onClick={() => {
              window.location = "/";
            }}
          />
        </div>
        <div>
          <div
            className="heading-top text-center"
            style={{ marginTop: "10rem" }}
          >
            <h1 className="h1-text">CONTACT</h1>
          </div>
          <div className="body-text text-center">
            <h5>
              <b>ILI.HOLDING GmbH</b>
            </h5>
            <p className="p-text">
              Ludwig-Erhard-Allee 20
              <br />
              76131 Karlsruhe
              <br />
              +49 721 619097-11
              <br />
              <a
                href="mailto:welcome@ili.digital"
                target="_blank"
                rel="noopener"
              >
                welcome@ili.holdings
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Contact);
