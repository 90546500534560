import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route} from 'react-router-dom';
import Home from './Home/Home';
import LegalNotice from './LegalNotice/LegalNotice';
import Contact from './Contact/Contact';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Footer from './Footer/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js';
import "./App.css"

function App() {
  return (
    <div>
      <Router>
        <Switch>
            <Route exact path="/" exact component={Home}/>
            <Route path="/legal-notice" exact component={LegalNotice}/>
            <Route path="/contact" exact component={Contact}/>
            <Route path="/data-policy" exact component={PrivacyPolicy}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
