import React, { Component } from "react";
import "./PrivacyPolicy.css";
import Footer from "../Footer/Footer";
import whiteIcon from "../Images/black.png";

export default class PrivacyPolicy extends Component {
  handleRoute = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="p-0 text-gray">
        <div className="header-class">
          <img
            src={whiteIcon}
            alt=""
            style={{
              float: "right",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
              width: "14rem",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location = "/";
            }}
          />
        </div>
        <div className="text-center text-heading">
          <h1 className="h1-text">PRIVACY POLICY</h1>
          <p className="mini-text">DATENSCHUTZ</p>
        </div>

        <div className="body">
          <div className="body-text">
            <p>
              Personal data (usually referred to just as “data” below) will only
              be processed by us to the
              <br />
              extent necessary and for the purpose of providing a functional and
              user-friendly website,
              <br />
              including its contents, and the services offered there.
              <br />
              Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General
              Data Protection Regulation
              <br /> (hereinafter referred to as the “GDPR”), “processing”
              refers to any operation or set of
              <br />
              operations such as collection, recording, organization,
              structuring, storage, adaptation,
              <br />
              alteration, retrieval, consultation, use, disclosure by
              transmission, dissemination, or otherwise
              <br />
              making available, alignment, or combination, restriction, erasure,
              or destruction performed
              <br />
              on personal data, whether by automated means or not. The following
              privacy policy is intended to inform you in particular about the
              type, scope,
              <br /> purpose, duration, and legal basis for the processing of
              such data either under our own
              <br />
              control or in conjunction with others. We also inform you below
              about the third-party
              <br />
              components we use to optimize our website and improve the user
              experience which may
              <br />
              result in said third parties also processing data they collect and
              control.
              <br />
            </p>
            <p className="mt-0">
              Our privacy policy is structured as follows:
              <br />
              I. Information about us as controllers of your data
              <br />
              II. The rights of users and data subjects
              <br />
              III. Information about the data processing
              <br />
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h3 className="mt-2">
              <strong>
                I. INFORMATION ABOUT US AS
                <br />
                CONTROLLERS OF YOUR DATA
                <br />
              </strong>
            </h3>
            <p>
              The party responsible for this website (the “controller”) for
              purposes of data protection law is:
            </p>
            <p className="mt-2">
              ILI.HOLDING
              <br />
              Ludwig-Erhard-Allee 20
              <br />
              76131 Karlsruhe
              <br />
              +49 721 6190970
              <br />
              <a
                href="mailto:welcome@ili.digital"
                target="_blank"
                rel="noopener"
              >
                welcome@ili.digital
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h3 className="mt-2">
              <strong>
                II. THE RIGHTS OF USERS AND DATA
                <br />
                SUBJECTS
              </strong>
            </h3>
            <p>
              With regard to the data processing to be described in more detail
              below, users and data
              <br />
              subjects have the right
              <br />
              <ul>
                <li>
                  to confirmation of whether data concerning them is being
                  processed, information about the data being processed, further
                  information about the nature of the data processing, and
                  copies of the data (cf. also Art. 15 GDPR);
                </li>
                <li>
                  to correct or complete incorrect or incomplete data (cf. also
                  Art. 16 GDPR);
                </li>
                <li>
                  to the immediate deletion of data concerning them (cf. also
                  Art. 17 DSGVO), or, alternatively, if further processing is
                  necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict
                  said processing per Art. 18 GDPR;
                </li>
                <li>
                  to receive copies of the data concerning them and/or provided
                  by them and to have the same transmitted to other
                  providers/controllers (cf. also Art. 20 GDPR);
                </li>
                <li>
                  to file complaints with the supervisory authority if they
                  believe that data concerning them is being processed by the
                  controller in breach of data protection provisions (see also
                  Art. 77 GDPR).
                </li>
              </ul>
            </p>
            <p>
              In addition, the controller is obliged to inform all recipients to
              whom it discloses data of any such corrections, deletions, or
              restrictions placed on processing the same per Art. 16, 17 Para.
              1, 18 GDPR. However, this obligation does not apply if such
              notification is impossible or involves a disproportionate effort.
              Nevertheless, users have a right to information about these
              recipients.
            </p>
            <p>
              <strong>
                Likewise, under Art. 21 GDPR, users and data subjects have the
                right to object to the controller’s future processing of their
                data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an
                objection to data processing for the purpose of direct
                advertising is permissible.
              </strong>
            </p>
            <p>&nbsp;</p>
            <h3 className="mt-2">
              <strong>III. Information about the data processing</strong>
            </h3>

            <p>
              Your data processed when using our website will be deleted or
              blocked as soon as the purpose for its storage ceases to apply,
              provided the deletion of the same is not in breach of any
              statutory storage obligations or unless otherwise stipulated
              below.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Server data</strong>
            </h4>
            <p>
              For technical reasons, the following data sent by your internet
              browser to us or to our server provider will be collected,
              especially to ensure a secure and stable website: These server log
              files record the type and version of your browser, operating
              system, the website from which you came (referrer URL), the
              webpages on our site visited, the date and time of your visit, as
              well as the IP address from which you visited our site.
            </p>
            <p>
              The data thus collected will be temporarily stored, but not in
              association with any other of your data.
            </p>
            <p>
              The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our
              legitimate interest lies in the improvement, stability,
              functionality, and security of our website.
            </p>
            <p>
              The data will be deleted within no more than seven days, unless
              continued storage is required for evidentiary purposes. In which
              case, all or part of the data will be excluded from deletion until
              the investigation of the relevant incident is finally resolved.
            </p>
            <p>&nbsp;</p>
            <h4>
              <strong>Cookies</strong>
            </h4>
            <p>a) Session cookies</p>
            <p>
              We use cookies on our website. Cookies are small text files or
              other storage technologies stored on your computer by your
              browser. These cookies process certain specific information about
              you, such as your browser, location data, or IP address.
            </p>
            <p>
              This processing makes our website more user-friendly, efficient,
              and secure, allowing us, for example, to display our website in
              different languages or to offer a shopping cart function.
            </p>
            <p>
              The legal basis for such processing is Art. 6 Para. 1 lit. b)
              GDPR, insofar as these cookies are used to collect data to
              initiate or process contractual relationships.
            </p>
            <p>
              If the processing does not serve to initiate or process a
              contract, our legitimate interest lies in improving the
              functionality of our website. The legal basis is then Art. 6 Para.
              1 lit. f) GDPR.
            </p>
            <p>
              When you close your browser, these session cookies are deleted.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <p>b) Third-party cookies</p>
            <p>
              If necessary, our website may also use cookies from companies with
              whom we cooperate for the purpose of advertising, analyzing, or
              improving the features of our website.
            </p>
            <p>
              Please refer to the following information for details, in
              particular for the legal basis and purpose of such third-party
              collection and processing of data collected through cookies.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <p>c) Disabling cookies</p>
            <p>
              You can refuse the use of cookies by changing the settings on your
              browser. Likewise, you can use the browser to delete cookies that
              have already been stored. However, the steps and measures required
              vary, depending on the browser you use. If you have any questions,
              please use the help function or consult the documentation for your
              browser or contact its maker for support. Browser settings cannot
              prevent so-called flash cookies from being set. Instead, you will
              need to change the setting of your Flash player. The steps and
              measures required for this also depend on the Flash player you are
              using. If you have any questions, please use the help function or
              consult the documentation for your Flash player or contact its
              maker for support.
            </p>
            <p>
              If you prevent or restrict the installation of cookies, not all of
              the functions on our site may be fully usable.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Contact</strong>
            </h4>
            <p>
              If you contact us via email or the contact form, the data you
              provide will be used for the purpose of processing your request.
              We must have this data in order to process and answer your
              inquiry; otherwise we will not be able to answer it in full or at
              all.
            </p>
            <p>
              The legal basis for this data processing is Art. 6 Para. 1 lit. b)
              GDPR.
            </p>
            <p>
              Your data will be deleted once we have fully answered your inquiry
              and there is no further legal obligation to store your data, such
              as if an order or contract resulted therefrom.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Follow-up comments</strong>
            </h4>
            <p>
              If you make posts on our website, we also offer you the
              opportunity to subscribe to any subsequent follow-up comments made
              by third parties. In order to be able to inform you about these
              follow-up comments, we will need to process your email address.
            </p>
            <p>
              The legal basis for this is Art. 6 Para. 1 lit. a) GDPR. You may
              revoke your prior consent to this subscription under Art. 7 Para.
              3 GDPR with future effect. All you have to do is inform us that
              you are revoking your consent or click on the unsubscribe link
              contained in each email.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Google Analytics</strong>
            </h4>
            <p>
              We use Google Analytics on our website. This is a web analytics
              service provided by Google Inc., 1600 Amphitheatre Parkway,
              Mountain View, CA 94043 (hereinafter: Google).
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              Google guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              The Google Analytics service is used to analyze how our website is
              used. The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our
              legitimate interest lies in the analysis, optimization, and
              economic operation of our site.
            </p>
            <p>
              Usage and user-related information, such as IP address, place,
              time, or frequency of your visits to our website will be
              transmitted to a Google server in the United States and stored
              there. However, we use Google Analytics with the so-called
              anonymization function, whereby Google truncates the IP address
              within the EU or the EEA before it is transmitted to the US.
            </p>
            <p>
              The data collected in this way is in turn used by Google to
              provide us with an evaluation of visits to our website and what
              visitors do once there. This data can also be used to provide
              other services related to the use of our website and of the
              internet in general.
            </p>
            <p>
              Google states that it will not connect your IP address to other
              data. In addition, Google provides further information with regard
              to its data protection practices at
            </p>
            <p>
              <a
                href="https://www.google.com/intl/de/policies/privacy/partners"
                target="_blank"
                rel="noopener"
              >
                https://www.google.com/intl/de/policies/privacy/partners,
              </a>
            </p>
            <p>
              including options you can exercise to prevent such use of your
              data.
            </p>
            <p>In addition, Google offers an opt-out add-on at</p>
            <p>
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener"
              >
                https://tools.google.com/dlpage/gaoptout?hl=en
              </a>
            </p>
            <p>
              in addition with further information. This add-on can be installed
              on the most popular browsers and offers you further control over
              the data that Google collects when you visit our website. The
              add-on informs Google Analytics’ JavaScript (ga.js) that no
              information about the website visit should be transmitted to
              Google Analytics. However, this does not prevent information from
              being transmitted to us or to other web analytics services we may
              use as detailed herein.
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Google+ plug-in</strong>
            </h4>
            <p>
              We use the plug-in of the Google+ social network on our website.
              Google+ is an online service provided by Google Inc., 1600
              Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter:
              Google).
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              Google guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in improving the quality of our website.
            </p>
            <p>
              Further information about the possible plug-ins and their
              respective functions is available from Google at
            </p>
            <p>
              <a
                href="https://developers.google.com/+/web/"
                target="_blank"
                rel="noopener"
              >
                https://developers.google.com/+/web/
              </a>
            </p>
            <p>
              If the plug-in is stored on one of the pages you visit on our
              website, your browser will download an icon for the plug-in from
              Google’s servers in the USA. For technical reasons, it is
              necessary for Google to process your IP address. In addition, the
              date and time of your visit to our website will also be recorded.
            </p>
            <p>
              If you are logged in to Google while visiting one of our
              plugged-in websites, the information collected by the plug-in from
              your specific visit will be recognized by Google. The information
              collected may then be assigned to your personal account at Google.
              If, for example, you use the +1 button, this information will be
              stored in your Google Account and may be published on the Google
              platform. To prevent this, you must either log out of Google
              before visiting our site or make the appropriate settings in your
              Google account.
            </p>
            <p>
              Further information about the collection and use of data as well
              as your rights and protection options in Google’s privacy policy
              found at
            </p>
            <p>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener"
              >
                https://policies.google.com/privacy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Google-Maps</strong>
            </h4>
            <p>
              Our website uses Google Maps to display our location and to
              provide directions. This is a service provided by Google Inc.,
              1600 Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter:
              Google).
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              Google guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              To enable the display of certain fonts on our website, a
              connection to the Google server in the USA is established whenever
              our website is accessed.
            </p>
            <p>
              If you access the Google Maps components integrated into our
              website, Google will store a cookie on your device via your
              browser. Your user settings and data are processed to display our
              location and create a route description. We cannot prevent Google
              from using servers in the USA.
            </p>
            <p>
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in optimizing the functionality of our website.
            </p>
            <p>
              By connecting to Google in this way, Google can determine from
              which website your request has been sent and to which IP address
              the directions are transmitted.
            </p>
            <p>
              If you do not agree to this processing, you have the option of
              preventing the installation of cookies by making the appropriate
              settings in your browser. Further details can be found in the
              section about cookies above.
            </p>
            <p>
              In addition, the use of Google Maps and the information obtained
              via Google Maps is governed by the{" "}
              <a
                href="http://www.google.de/accounts/TOS"
                target="_blank"
                rel="noopener"
              >
                Google Terms of Use{" "}
              </a>{" "}
              <a
                href="https://policies.google.com/terms?gl=DE&amp;hl=de"
                target="_blank"
                rel="noopener"
              >
                https://policies.google.com/terms?gl=DE&amp;hl=en
              </a>{" "}
              and the{" "}
              <a
                href="http://www.google.com/intl/de_de/help/terms_maps.html"
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions for Google Maps
              </a>
              https://www.google.com/intl/de_de/help/terms_maps.html.
            </p>
            <p>Google also offers further information at</p>
            <p>
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <p>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener"
              >
                https://policies.google.com/privacy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Facebook plug-in</strong>
            </h4>
            <p>
              Our website uses the plug-in of the Facebook social network.
              Facebook.com is a service provided by Facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304, USA. In the EU, this service
              is also operated by Facebook Ireland Limited, 4 Grand Canal
              Square, Dublin 2, Ireland, hereinafter both referred to as
              “Facebook.”
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
              </a>
            </p>
            <p>
              Twitter guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in improving the quality of our website.
            </p>
            <p>
              If the plug-in is stored on one of the pages you visit on our
              website, your browser will download an icon for the plug-in from
              Twitter’s servers in the USA. For technical reasons, it is
              necessary for Twitter to process your IP address. In addition, the
              date and time of your visit to our website will also be recorded.
            </p>
            <p>
              If you are logged in to Twitter while visiting one of our
              plugged-in websites, the information collected by the plug-in from
              your specific visit will be recognized by Twitter. The information
              collected may then be assigned to your personal account at
              Twitter. If, for example, you use the Twitter Tweet button, this
              information will be stored in your Twitter account and may be
              published on the Twitter platform. To prevent this, you must
              either log out of Twitter before visiting our site or make the
              appropriate settings in your Twitter account.
            </p>
            <p>
              Further information about the collection and use of data as well
              as your rights and protection options in Twitter’s privacy policy
              found at
            </p>
            <p>
              <a
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener"
              >
                https://twitter.com/privacy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Use of Xing recommendations</strong>
            </h4>
            <p>
              Our website uses the plug-in of Xing. The Xing service is operated
              by Xing Dammtorstraße 29-32, 20354 Hamburg, Germany.
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
              </a>
            </p>
            <p>
              Xing guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in improving the quality of our website.
            </p>
            <p>
              If you are logged in to Xing while visiting one of our plugged-in
              websites, the information collected by the plug-in from your
              specific visit will be recognized by Xing. The information
              collected may then be assigned to your personal account at Xing.
            </p>
            <p>
              Further information about the collection and use of data as well
              as your rights and protection options in Xing’s privacy policy
              found at
            </p>
            <p>
              <a
                href="https://www.xing.com/app/share?op=data_protection"
                target="_blank"
                rel="noopener"
              >
                https://Xing.com/privacy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>Linked-in recommendations</strong>
            </h4>
            <p>
              Our website uses the plug-in of the LinkedIn social network. The
              LinkedIn service is operated by LinkedIn{" "}
              <span lang="EN-US">
                Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA
              </span>
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
              </a>
            </p>
            <p>
              LinkedIn guarantees that it will follow the EU’s data protection
              regulations when processing data in the United States.
            </p>
            <p>
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in improving the quality of our website.
            </p>
            <p>
              If the plug-in is stored on one of the pages you visit on our
              website, your browser will download an icon for the plug-in from
              LinkedIn servers in the USA. For technical reasons, it is
              necessary for LinkedIn to process your IP address. In addition,
              the date and time of your visit to our website will also be
              recorded.
            </p>
            <p>
              If you are logged in to LinkedIn while visiting one of our
              plugged-in websites, the information collected by the plug-in from
              your specific visit will be recognized by LinkedIn. The
              information collected may then be assigned to your personal
              account at LinkedIn. &nbsp;To prevent this, you must either log
              out of LinkedIn before visiting our site or make the appropriate
              settings in your LinkedIn account.
            </p>
            <p>
              Further information about the collection and use of data as well
              as your rights and protection options in LinkedIn privacy policy
              found at
            </p>
            <p>
              <a
                href="http://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="noopener"
              >
                https://linkedin.com/legal/privacy-policy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>&nbsp;</h4>
            <h4>
              <strong>YouTube</strong>
            </h4>
            <p>
              We use YouTube on our website. This is a video portal operated by
              YouTube LLC, 901 Cherry Ave, 94066 San Bruno, CA, USA, hereinafter
              referred to as “YouTube”.
            </p>
            <p>
              YouTube is a subsidiary of Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043 USA, hereinafter referred to as “Google”.
            </p>
            <p>Through certification according to the EU-US Privacy Shield</p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                target="_blank"
                rel="noopener"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              Google and its subsidiary YouTube guarantee that they will follow
              the EU’s data protection regulations when processing data in the
              United States.
            </p>
            <p>
              We use YouTube in its advanced privacy mode to show you videos.
              The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
              interest lies in improving the quality of our website. According
              to YouTube, the advanced privacy mode means that the data
              specified below will only be transmitted to the YouTube server if
              you actually start a video.
            </p>
            <p>
              Without this mode, a connection to the YouTube server in the USA
              will be established as soon as you access any of our webpages on
              which a YouTube video is embedded.
            </p>
            <p>
              This connection is required in order to be able to display the
              respective video on our website within your browser. YouTube will
              record and process at a minimum your IP address, the date and time
              the video was displayed, as well as the website you visited. In
              addition, a connection to the DoubleClick advertising network of
              Google is established.
            </p>
            <p>
              If you are logged in to YouTube when you access our site, YouTube
              will assign the connection information to your YouTube account. To
              prevent this, you must either log out of YouTube before visiting
              our site or make the appropriate settings in your YouTube account.
            </p>
            <p>
              For the purpose of functionality and analysis of usage behavior,
              YouTube permanently stores cookies on your device via your
              browser. If you do not agree to this processing, you have the
              option of preventing the installation of cookies by making the
              appropriate settings in your browser. Further details can be found
              in the section about cookies above.
            </p>
            <p>
              Further information about the collection and use of data as well
              as your rights and protection options in Google’s privacy policy
              found at
            </p>
            <p>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener"
              >
                https://policies.google.com/privacy
              </a>
            </p>
            <p>&nbsp;</p>
            <h4>
              <strong>Online job applications / publications</strong>
              <strong>&nbsp;of job advertisements</strong>
            </h4>
            <p>
              We offer you the opportunity to apply for jobs with our company
              via our website. In the case of these digital applications, we
              collect your application data electronically in order to process
              your application.
            </p>
            <p>
              The legal basis for this processing is §26 Para. 1 S. 1 BDSG in
              conjunction with Art. 88 Para. 1 GDPR.
            </p>
            <p>
              If you are hired as a result of the application process, we will
              store the data you provide during the application process in your
              personnel file for the purpose of the usual organizational and
              administrative process, naturally in compliance with further legal
              obligations.
            </p>
            <p>
              The legal basis for this processing is §26 Para. 1 S. 1 BDSG in
              conjunction with Art. 88 Para. 1 GDPR.
            </p>
            <p>
              If we do not hire you, we will automatically delete the data
              submitted to us two months after the final decision is made. We
              will not delete the data, however, if we must store the data for
              legal reasons such as evidence of equal treatment of applicants,
              until any legal action is concluded, or four months.
            </p>
            <p>
              In this case, the legal basis is Art. 6 Para. 1 lit. f) GDPR and
              §24 Para. 1 No. 2 BDSG. Our legitimate interest lies in any legal
              defense we may have to mount .
            </p>
            <p>
              If you expressly consent to a longer storage of your data, e.g.
              for your inclusion in a database of applicants or interested
              parties, the data will be processed further on the basis of your
              consent. The legal basis is then Art. 6 Para. 1 lit. a) GDPR. You
              may withdraw your consent at any time with future effect per Art.
              7 Para. 3 GDPR with future effect.
            </p>
            <p>
              <a
                href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
                target="_blank"
                rel="noopener"
              >
                Model Data Protection Statement
              </a>{" "}
              for{" "}
              <a
                href="https://www.ratgeberrecht.eu/"
                target="_blank"
                rel="noopener"
              >
                Anwaltskanzlei Weiß &amp; Partner
              </a>
            </p>
            <p>&nbsp;</p>
            <div className="lower-section text-center">
              <h3 class="elementor-heading-title">
                <strong>Datenschutzerklärung</strong>
              </h3>
            </div>
            <h3>
              <strong>1. DATENSCHUTZ AUF EINEN BLICK</strong>
            </h3>
            <h4 className="h4-text">
              <span className="span-text">Allgemeine Hinweise</span>
            </h4>
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Datenerfassung auf dieser Website
              </span>
            </h4>
            <p>
              <strong>
                Wer ist verantwortlich für die Datenerfassung auf dieser
                Website?
              </strong>
            </p>
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen.
            </p>
            <p>
              <strong>Wie erfassen wir Ihre Daten?</strong>
            </p>
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
              Sie in ein Kontaktformular eingeben.
            </p>
            <p>
              Andere Daten werden automatisch beim Besuch der Website durch
              unsere IT-Systeme erfasst. Das sind vor allem technische Daten
              (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
              Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
              sobald Sie diese Website betreten.
            </p>
            <p>
              <strong>Wofür nutzen wir Ihre Daten?</strong>
            </p>
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>
            <p>
              <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
            </p>
            <p>
              Sie haben jederzeit das Recht unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Hierzu
              sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
              zuständigen Aufsichtsbehörde zu.
            </p>
            <p>
              Außerdem haben Sie das Recht, unter bestimmten Umständen die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
              verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
              unter „Recht auf Einschränkung der Verarbeitung“.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Analyse-Tools und Tools von Drittanbietern
              </span>
            </h4>
            <p>
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit Cookies und mit
              sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
              erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
              Ihnen zurückverfolgt werden.
            </p>
            <p>
              Sie können dieser Analyse widersprechen oder sie durch die
              Nichtbenutzung bestimmter Tools verhindern. Detaillierte
              Informationen zu diesen Tools und über Ihre
              Widerspruchsmöglichkeiten finden Sie in der folgenden
              Datenschutzerklärung.
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>2. Hosting und Content Delivery Networks (CDN)</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text"> Externes Hosting</span>
            </h4>
            <p>
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Webseitenzugriffe und sonstige Daten, die über eine Website
              generiert werden, handeln.
            </p>
            <p>
              Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
              gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
              1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
              effizienten Bereitstellung unseres Online-Angebots durch einen
              professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            </p>
            <p>
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erfüllung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>3. Allgemeine Hinweise und Pflichtinformationen</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text"> Datenschutz</span>
            </h4>
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
            </p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet
              (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitslücken
              aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
              durch Dritte ist nicht möglich.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Widerruf Ihrer Einwilligung zur Datenverarbeitung
              </span>
            </h4>
            <p>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
              Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                sowie gegen Direktwerbung (Art. 21 DSGVO)
              </span>
            </h4>
            <p>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </p>
            <p>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Beschwerderecht bei der zuständigen Aufsichtsbehörde
              </span>
            </h4>
            <p>
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">SSL- bzw. TLS-Verschlüsselung</span>
            </h4>
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </p>
            <p>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Auskunft, Löschung und Berichtigung
              </span>
            </h4>
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Recht auf Einschränkung der Verarbeitung
              </span>
            </h4>
            <p>
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
              folgenden Fällen:
            </p>
            <ul>
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
            </ul>
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>4. Datenerfassung auf dieser Website</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Cookies</span>
            </h4>
            <p>
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert bis Sie diese selbst löschen&nbsp;oder eine
              automatische Lösung durch Ihren Webbrowser erfolgt.
            </p>
            <p>
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>
            <p>
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu das
              Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.
            </p>
            <p>
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von
              Ihnen erwünschter Funktionen (z. B. Warenkorbfunktion)
              erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f
              DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Speicherung von Cookies zur technisch
              fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
              eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>
            <p>
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Server-Log-Dateien</span>
            </h4>
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
            <p>
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </p>
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Kontaktformular</span>
            </h4>
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
              Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">
                Anfrage per E-Mail, Telefon oder Telefax
              </span>
            </h4>
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f
              DSGVO), da wir ein berechtigtes Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen haben.
            </p>
            <p>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z.&nbsp;B. nach abgeschlossener
              Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>5. Soziale Medien</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Instagram Plugin</span>
            </h4>
            <p>
              Auf dieser Website sind Funktionen des Dienstes Instagram
              eingebunden. Diese Funktionen werden angeboten durch die Instagram
              Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.
            </p>
            <p>
              Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
              durch Anklicken des Instagram-Buttons die Inhalte dieser Website
              mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
              Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram
              erhalten.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Instagram:{" "}
              <a
                href="https://instagram.com/about/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://instagram.com/about/legal/privacy/
              </a>
              .
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>6. Newsletter</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Newsletterdaten</span>
            </h4>
            <p>
              Wenn Sie den auf der Website angebotenen Newsletter beziehen
              möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
              Informationen, welche uns die Überprüfung gestatten, dass Sie der
              Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang
              des Newsletters einverstanden sind. Weitere Daten werden nicht
              bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
              ausschließlich für den Versand der angeforderten Informationen und
              geben diese nicht an Dritte weiter.
            </p>
            <p>
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
              Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
              Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
              Versand des Newsletters können Sie jederzeit widerrufen, etwa über
              den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
              erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </p>
            <p>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters aus der
              Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken
              bei uns gespeichert wurden bleiben hiervon unberührt.
            </p>
            <p>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, um künftige Mailings zu verhindern.
              Die Daten aus der Blacklist werden nur für diesen Zweck verwendet
              und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
              Ihrem Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet.{" "}
              <strong>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen
                unser berechtigtes Interesse überwiegen.
              </strong>
            </p>
            <h2>&nbsp;</h2>
            <h3>
              <strong>7. Plugins und Tools</strong>
            </h3>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Google Web Fonts</span>
            </h4>
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Web Fonts, die von Google bereitgestellt werden. Beim
              Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
              ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            </p>
            <p>
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
              zu den Servern von Google aufnehmen. Hierdurch erlangt Google
              Kenntnis darüber, dass über Ihre IP-Adresse diese Website
              aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber
              hat ein berechtigtes Interesse an der einheitlichen Darstellung
              des Schriftbildes auf seiner Webseite. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>
            <p>
              Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </p>
            <p>
              Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
              <a
                href="https://developers.google.com/fonts/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://developers.google.com/fonts/faq
              </a>{" "}
              und in der Datenschutzerklärung von Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Google Maps</span>
            </h4>
            <p>
              Diese Seite nutzt über eine API den Kartendienst Google Maps.
              Anbieter ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA übertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Datenübertragung.
            </p>
            <p>
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer Online-Angebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar.
            </p>
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h3>&nbsp;</h3>
            <h4 className="h4-text">
              <span className="span-text">Google reCAPTCHA</span>
            </h4>
            <p>
              Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf
              dieser Website. Anbieter ist die Google Ireland Limited
              („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf
              dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen
              Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
              analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
              verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald
              der Websitebesucher die Website betritt. Zur Analyse wertet
              reCAPTCHA verschiedene Informationen aus (z.&nbsp;B. IP-Adresse,
              Verweildauer des Websitebesuchers auf der Website oder vom Nutzer
              getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
              werden an Google weitergeleitet.
            </p>
            <p>
              Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
              Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
              stattfindet.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse daran, seine Webangebote vor
              missbräuchlicher automatisierter Ausspähung und vor SPAM zu
              schützen. Sofern eine entsprechende Einwilligung abgefragt wurde
              (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
              Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
              unter folgenden Links:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>{" "}
              und{" "}
              <a
                href="https://policies.google.com/terms?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/terms?hl=de
              </a>
              .
            </p>
            <p>
              Quelle:{" "}
              <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">
                https://www.e-recht24.de/muster-datenschutzerklaerung.html
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
