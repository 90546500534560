import React, { Component } from "react";
import "./LegalNotice.css";
import Footer from "../Footer/Footer";
import whiteIcon from "../Images/black.png";

export default class LegalNotice extends Component {
  handleRoute = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="h-100">
        <div className="header-class">
          <img
            src={whiteIcon}
            alt=""
            style={{
              float: "right",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
              width: "14rem",
              cursor:"pointer"
            }}
            onClick={() => {
              window.location = "/";
            }}
          />
        </div>
        <div>
          <div
            className="heading-top text-center"
            style={{ marginTop: "10rem" }}
          >
            <h1 className="h1-text">LEGAL NOTICE</h1>
            <p className="mt-2 mini-text">IMPRESSUM</p>
          </div>
          <div className="body-text text-center">
            <h5>
              <b>ILI.HOLDING GmbH</b>
            </h5>
            <p className="p-text">
              Ludwig-Erhard-Allee 20
              <br />
              76131 Karlsruhe
              <br />
              +49 721 619097-11
              <br />
              <a
                href="mailto:welcome@ili.digital"
                target="_blank"
                rel="noopener"
              >
                welcome@ili.holdings
              </a>
            </p>
            <p style={{ marginTop: "8rem" }}>
              Responsible for content:<br></br>
              <br></br>
              Dr. Serhan Ili<br></br>
              Ludwig-Erhard-Allee 20<br></br>
              76131 Karlsruhe<br></br>
              <br></br>
              HRB 722615, Amtsgericht Mannheim<br></br>
              Corporate Headquarters: Karlsruhe<br></br>
              USt.-ID: DE 337 550 485<br></br>© Copyright 2020 ILI.HOLDING – All
              rights reserved.<br></br>
              <br></br>
              The content of this website is protected by copyright. All content
              may only be used,<br></br>
              even in part, with the prior written consent of ILI.HOLDING.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
